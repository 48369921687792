declare var writeText: (text: string) => Promise<void>;
/**
 * クリップボードにテキストをコピーします．
 * @param text コピーするテキスト
 */
export function copyToClipBoard(text: string) {
    const listener = (e: ClipboardEvent) => {
        if (!e.clipboardData) return;
        e.clipboardData.setData("text/plain", text);
        e.preventDefault();
        document.removeEventListener("copy", listener);
    };
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    navigator.clipboard.writeText(text);
}
