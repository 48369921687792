import { delay } from "./timer";

/**
 * エレメントをアニメーションします
 * @param element アニメーションするエレメント
 * @param property アニメーションするCSSプロパティ
 * @param value セットする値
 * @param duration 何秒かけてアニメーションするか(s)
 * @param deleyTime アニメーション開始を遅らせる時間(ms)
 */
export async function animate(element: HTMLElement, property: string, value: string, duration: number, deleyTime = 0): Promise<void> {
    if (!element) {
        await delay(deleyTime);
        await delay(duration * 1000);
        return;
    }
    element.style.transition = "all " + duration + "s";
    await delay(deleyTime);
    (element.style as any)[property] = value;
    await delay(duration * 1000);
};
